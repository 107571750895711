import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Container, Header, Content, Image, Loading, TextEmail, ButtonCopy, TextWhatsApp, TextNomeConsultor, Email, ConsultorDiv } from "./styles";
import Menu from "./Menu";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useSideBar } from "../../contexts/SideBarProvider";
import LogoSolar from "../../assets/images/logos/adias/solar_branco.svg";
import logoDark from "../../assets/images/logos/adias/ar_branco.svg";
import Loader from "../../components/Loader";
import { WhatsappLogo } from "phosphor-react";
import { useColors } from "../../hooks/useColors";
import { copyToClipboard } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { useSolar, useViewport } from "../../hooks";
import { useProfile } from "../../hooks/UserHooks";
import { getMenuItems, getSuperiorRevenda } from "../../store/actions";
import { Spinner, Col, Button, Tooltip } from "reactstrap";
import SvgIcon from "../SvgIcon";

export default function SideBar() {
  const { openSidebar, setOpenSidebar } = useSideBar();
  const [openSubMenu, setOpenSubMenu] = useState({ open: false, id: 0 });
  const [idSubMenuSelected, setIdSubMenuSelected] = useState(0);
  const { width } = useViewport();
  const dispatch = useDispatch();
  const isSolar = useSolar();
  const history = useHistory();
  const location = useLocation();
  const {
    sidebarMenuData,
    getMenusLoading,
    isMenuItemFetched,
    registrationDetails,
    loadingSuperior,
  } = useSelector(state => ({
    sidebarMenuData: state.SidebarMenu.menuItems,
    getMenusLoading: state.SidebarMenu.getMenusLoading,
    isMenuItemFetched: state.SidebarMenu.isMenuItemFetched,
    registrationDetails: state.RegistrationsReducer.registrationDetails,
    loadingSuperior: state.RegistrationsReducer.getSuperiorLoading,
  }));
  const [emailConsultor, setEmailConsultor] = useState('');
  const [isTooltipWhatsAppOpen, setIsTooltipWhatsAppOpen] = useState(false);
  const [isTooltipEmailOpen, setIsTooltipEmailOpen] = useState(false);
  const { colorDefault, color500, colorLight } = useColors();
  const { userProfile } = useProfile();
  const displayName = userProfile
    ? userProfile["displayName"] !== null
      ? userProfile["displayName"].split(" ")[0]
      : userProfile["email"]
    : "";

  function tToggle() {
    var body = document.body;

    if (document.body.classList.contains("sidebar-enable") && width > 1300) {
      body.classList.remove("vertical-collpsed");
      body.classList.remove("sidebar-enable");
      setOpenSidebar(true);
      return;
    }
    if (document.body.classList.contains("sidebar-enable")) {
      return;
    }
    if (width <= 1300) {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
      setOpenSidebar(false);
    }
  }

  useEffect(() => {
    dispatch(getSuperiorRevenda());
  }, [dispatch]);

  useEffect(() => {
    if(isTooltipWhatsAppOpen)
    {
      setTimeout(() => {
        setIsTooltipWhatsAppOpen(false);
      }, 1500);
    }
    else if (isTooltipEmailOpen)
    {
      setTimeout(() => {
        setIsTooltipEmailOpen(false);
      }, 1500);
    }
  }, [isTooltipWhatsAppOpen, isTooltipEmailOpen])

  useEffect(() => {
    setEmailConsultor(registrationDetails?.['EMAIL']);
  }, [registrationDetails]);

  useEffect(() => {
    tToggle();
  }, [width]);
  //se mudar a empresa ele valida e envia uma nova requisição para trazer os menus da empresa certa
  useEffect(() => {
    if (!getMenusLoading) {
      if (isSolar) {
        dispatch(getMenuItems({ empresaid: "solar" }));
      } else {
        dispatch(getMenuItems({ empresaid: "ar" }));
      }
    }
  }, [isSolar]);

  const getSubMenuSelected = () => {
    let idSelect = 0;
    let idSubMenuSelect = 0;
    for (var i = 0; i < sidebarMenuData?.length; i++) {
      for (var j = 0; j < sidebarMenuData[i]?.subItems?.length; j++) {
        if (sidebarMenuData[i]?.subItems[j]?.link == location.pathname) {
          idSelect = sidebarMenuData[i]?.id;
          idSubMenuSelect = sidebarMenuData[i]?.subItems[j]?.id;
        }
      }
    }
    setIdSubMenuSelected(idSelect);
  };
  //pega o menu selecionado pela rota
  useEffect(() => {
    if (sidebarMenuData) {
      getSubMenuSelected();
    }
  }, [sidebarMenuData, location]);

  const logoutErrorRequestMenu = () => {
    if (sidebarMenuData.lenght == 0) {
      history.push("/logout");
    }
  };

  //caso nao traga os dados do menu em 5000 ele executa essa função para deslogar o usuario
  useEffect(() => {
    setTimeout(() => {
      logoutErrorRequestMenu();
    }, 5000);
  }, []);

  const messageWpp = registrationDetails && registrationDetails["NOME"] 
  ? `Olá! Sou ${displayName} e estou no Clube ADias. Gostaria de mais informações.` 
  : '';
  const url = registrationDetails && registrationDetails["TEL"]
    ? `https://wa.me/${registrationDetails["TEL"]}?text=${messageWpp}`
    : '';


  return (
    <Container openSidebar={openSidebar}>
      <Header isSolar={isSolar}>
        {width >= 1000 && (
          <Image
            openSidebar={openSidebar}
            src={isSolar ? LogoSolar : logoDark}
            alt="Logo"
          />
        )}
      </Header>
      <Content openSidebar={openSidebar} isSolar={isSolar}>
        {getMenusLoading ? (
          <Loading>
            <Spinner style={{ color: isSolar ? "#FF9E73" : "#fff" }} />
          </Loading>
        ) : (
          (sidebarMenuData || [])
            .sort((a, b) => (a.ordem > b.ordem ? 1 : -1))
            .map((item, k) => (
              <Menu
                key={k}
                item={item}
                openSidebar={openSidebar}
                openSubMenu={openSubMenu}
                setOpenSubMenu={setOpenSubMenu}
                idSubMenuSelected={idSubMenuSelected}
              />
            ))
        )}
        {openSidebar && !loadingSuperior && registrationDetails && registrationDetails["NOME"] && (
          <ConsultorDiv
            className="ms-2 mt-3 p-2 rounded-3"
          >
            <h6 className="text-white mb-3 p-2 d-flex flex-row align-items-center">
              Fale com o seu consultor{" "}
              <SvgIcon
                name="phone-calling"
                color="white"
                className="icon icon-xs ms-2"
              />
            </h6>
            <div
              className="p-2 rounded-4 m-1"
              style={{
                backgroundColor: isSolar ? "#ffe0d342" : "#7eacc466"
              }}
            >
              <TextNomeConsultor className="text-white mb-1 ps-2 pt-1 h6">
                {registrationDetails?.NOME}
              </TextNomeConsultor>
              <Button 
                  className="text-white text-opacity-75 w-100 shadow-sm btn-success p-1 rounded-4 d-flex justify-content-between"
              >
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-flex"
                >
                  <div className="d-flex justify-content-center align-items-center px-1">
                    <WhatsappLogo size={16} weight="light" color="#ffffffbf" />
                  </div>
                  <TextWhatsApp className="text-white rounded-4 pt-1 d-flex">
                    ({registrationDetails?.DDD_TEL}){" "}
                    {registrationDetails?.TELEFONE}
                  </TextWhatsApp>
                </a>
                <Button
                  onClick={() => {copyToClipboard(registrationDetails?.DDD_TEL + registrationDetails?.TELEFONE || ""); 
                    setIsTooltipWhatsAppOpen(true);
                  }}
                  className="ms-2 p-0 bg-transparent btn-success px-1 rounded-circle"
                  id="tooltip-whatsapp-btn"
                >
                  <Tooltip
                    target={"tooltip-whatsapp-btn"}
                    isOpen={isTooltipWhatsAppOpen}
                    placement="top"
                    toggle={() => setIsTooltipWhatsAppOpen(!isTooltipWhatsAppOpen)} 
                    trigger="manual" 
                    style={{ backgroundColor: colorDefault }}
                  >
                    Copiado!
                  </Tooltip>
                  <SvgIcon
                    name="copy"
                    color="white"
                    className="icon icon-xxs h-auto"
                  />
                  
                </Button>
              </Button>
              <div 
                className="bg-white btn rounded-4 p-1 bg-opacity-75 mt-1 d-flex justify-content-between shadow pe-1"
                style={{ backgroundColor: color500 }}
              >
                <a
                  href={`mailto:${emailConsultor?.toLowerCase()}`}
                  target="_blank"
                  rel="noreferrer"
                  className="d-flex align-items-center p-0 px-1"
                >
                  <SvgIcon
                    name="email"
                    color={colorDefault}
                    className="icon icon-xxs h-auto me-1"
                  />
                  <TextEmail
                    className="text-email"
                    style={{ color: colorDefault }}
                  >{emailConsultor}</TextEmail>
                </a>
                <ButtonCopy
                  onClick={() => {copyToClipboard(emailConsultor); setIsTooltipEmailOpen(true);}}
                  className="p-0 bg-transparent btn bg-opacity-75 px-1 rounded-circle btn-copy-contact"
                  id="copy-email-btn"
                  isSolar={isSolar}
                >
                  <Tooltip
                    target={"copy-email-btn"}
                    isOpen={isTooltipEmailOpen}
                    placement="top"
                    toggle={() => setIsTooltipEmailOpen(!isTooltipEmailOpen)}
                    trigger="manual"
                    style={{ backgroundColor: colorDefault }}
                  >
                    Copiado!
                  </Tooltip>
                  <SvgIcon
                    name="copy"
                    color={colorDefault}
                    className="icon icon-xxs h-auto"
                  />
                </ButtonCopy>
              </div>
            </div>
          </ConsultorDiv>
        )}
      </Content>
    </Container>
  );
}
